import Icon from '@/components/icon';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useAtEnd, useScrollToBottom } from 'react-scroll-to-bottom';

const useStyles = createUseStyles({
  followButton: {
    position: 'absolute',
    right: 20,
    bottom: 5,
    cursor: 'pointer',
    opacity: 0.8,
    '& svg': {
      transform: 'rotate(90deg)',
    },
  },
});

const FollowButton: FC = () => {
  const [atEnd] = useAtEnd();
  const scrollToBottom = useScrollToBottom();
  const styles = useStyles();
  const hideTimeoutId = useRef<NodeJS.Timeout | null>(null);
  const [showButton, setShowButton] = useState(false);

  const handleClick = useCallback(() => {
    scrollToBottom();
  }, [scrollToBottom]);

  useEffect(() => {
    if (atEnd) {
      if (hideTimeoutId.current) {
        clearTimeout(hideTimeoutId.current);
      }
      setShowButton(false);
      return;
    }

    hideTimeoutId.current = setTimeout(() => {
      setShowButton(true);
    }, 400);
  }, [atEnd]);

  if (!showButton) {
    return null;
  }

  return <Icon name="arrow-circle-right" className={styles.followButton} onClick={handleClick} />;
};

export default FollowButton;
