import { Box, Stack, Theme } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import Text from '../text';
import { createUseStyles } from 'react-jss';
import TextInput from '../form/text-input';
import Icon from '../icon';
import Switch from '../form/switch';
import useUserSettings from '@/hooks/use-user-settings.hook';
import DatasourceList from './datasource-list';

const useStyles = createUseStyles((theme: Theme) => ({
  contextTab: {
    paddingLeft: 18,
  },
  header: {
    padding: '0px 12px 10px 0px',
  },
  searchInput: {
    marginTop: 20,
    paddingRight: 6,
  },
  noDatasourcesCard: {
    marginTop: 16,
    padding: 8,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[400]}`,
    margin: '0px 18px 0px 0px',
    display: 'flex',
    justifyContent: 'center',
  },
  publicSearchCard: {
    marginTop: 16,
    padding: 8,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[400]}`,
    margin: '0px 18px 0px 0px',
    '& .MuiFormControl-root': {
      margin: 0,
    },
  },
}));

const UserSettingsTab: FC = () => {
  const styles = useStyles();
  const [keyword, setKeyword] = useState('');

  const { enablePublicSearch, disablePublicSearch, datasources, isLoading } = useUserSettings();

  const handleKeywordChange = (value: string) => {
    setKeyword(value);
  };

  const handleTogglePublicSearch = (checked: boolean) => {
    if (checked) {
      enablePublicSearch();
      return;
    }

    disablePublicSearch();
  };

  const publicSearchEnabled = useMemo(() => {
    return Array.from(datasources.entries()).some(
      ([, { model, enabled }]) => model.isPublicInternet && enabled
    );
  }, [datasources]);

  const [filteredDatasources, publicInternetIds] = useMemo(() => {
    const safeKeyword = String(keyword).trim().toLowerCase();
    const publicInternetIds: string[] = [];

    const filteredData = Array.from(datasources.entries())
      .filter(([, { model }]) => {
        const { id, name, description, isPublicInternet } = model;

        if (isPublicInternet) {
          publicInternetIds.push(id);
          return false;
        }

        const keywordMatch =
          String(name).toLowerCase().includes(safeKeyword) ||
          String(description).toLowerCase().includes(safeKeyword);

        return keywordMatch;
      })
      .map(([, datasource]) => datasource);

    return [filteredData, publicInternetIds];
  }, [datasources, keyword]);

  const canPublicSearch = !!publicInternetIds.length;

  const hasDatasources = !!filteredDatasources.length;
  const noResults = !isLoading && !!keyword.length && !hasDatasources;
  const noAvailableDatasources = !keyword.length && !hasDatasources && !isLoading;

  return (
    <Stack gap={2} width="100%" height="100%" maxHeight="100%" className={styles.contextTab}>
      <Stack gap={2} className={styles.header}>
        <Stack direction="row" gap={1} alignItems="center">
          <Icon name="manage-search" />
          <Text size="large">Default Data Sources</Text>
        </Stack>

        {noAvailableDatasources && !canPublicSearch && (
          <Box className={styles.noDatasourcesCard}>
            <Text italic>No data sources available</Text>
          </Box>
        )}

        {!noAvailableDatasources && (
          <Box className={styles.searchInput}>
            <TextInput
              name="datasource-search"
              value={keyword}
              onChange={handleKeywordChange}
              size="small"
              startIcon="search"
              placeholder="Search data sources"
              clearable
            />
          </Box>
        )}
      </Stack>

      {!noAvailableDatasources && (
        <DatasourceList
          isLoading={isLoading}
          datasources={filteredDatasources}
          emptyMessage={noResults ? 'No matching data sources found' : ''}
        />
      )}

      {canPublicSearch && (
        <Box className={styles.publicSearchCard}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" alignItems="center" gap={0.5} flexGrow={1}>
              <Icon name="globe" size="small" />
              <Text size="small">Public Internet Search</Text>
            </Stack>

            <Stack justifyContent="center">
              <Switch
                name="public-internet-search"
                checked={publicSearchEnabled}
                onChange={handleTogglePublicSearch}
                size="small"
              />
            </Stack>
          </Stack>
        </Box>
      )}
    </Stack>
  );
};

export default UserSettingsTab;
