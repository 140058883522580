import { BaseModel } from './base.model';
import { JsonObject } from '../helpers';

export type DatasourceSource = 'sharepoint-online' | 'webcrawler' | 'public-internet' | 'unknown';

export class DatasourceModel extends BaseModel {
  name: string;
  source: DatasourceSource;
  description: string;

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof DatasourceModel, unknown>;

    this.name = (data.name as string) || '';
    this.source = (data.source as DatasourceSource) || 'unknown';
    this.description = (data.description as string) || '';
  }

  get isPublicInternet(): boolean {
    return ['brave-search-api', 'public-internet'].includes(this.source);
  }
}
