import { Theme } from '@mui/material';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  count?: number;
}

const useStyles = createUseStyles((theme: Theme) => ({
  loaderDots: {
    height: 20,
    marginBottom: 5,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },

  dot: {
    borderRadius: '50%',
    backgroundColor: theme.palette.grey[400],
    borderColor: theme.palette.grey[400],
    width: 10,
    height: 10,
    animationDuration: '500ms',
    animationName: '$changetransform',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    marginLeft: 4,

    '&:nth-child(2)': {
      animationDelay: '100ms',
    },

    '&:nth-child(3)': {
      animationDelay: '200ms',
    },
  },

  '@keyframes changetransform': {
    from: { transform: 'translateY(0px)' },
    to: { transform: 'translateY(-10px)' },
  },
}));

const LoaderDots: FC<Props> = ({ count = 1 }) => {
  const styles = useStyles();
  return (
    <div className={styles.loaderDots}>
      {Array.from(Array(count).keys()).map((val) => (
        <div key={val} className={styles.dot}></div>
      ))}
    </div>
  );
};

export default LoaderDots;
